<template>
    <v-footer padless>
        <v-col class="text-center" cols="12">
            Copyright @{{ new Date().getFullYear() }} — <strong>冠灵医药</strong>
        </v-col>
    </v-footer>
</template>
<script>
    export default {
        name: "foot",
        data() {
            return {

            }
        }
    }
</script>