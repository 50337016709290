<template>
  <v-app>

    <Head ref="head"></Head>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="() => {this.$refs.head.drawer = !this.$refs.head.drawer}"></v-app-bar-nav-icon>
      <v-toolbar-title>{{$route.meta.name}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <span>您好,{{name}}</span>
      <v-btn text color="primary" @click="logout">登出</v-btn>
    </v-app-bar>
    <v-main>
      <router-view v-if="loaded" />
      <v-overlay absolute v-else>
        <v-progress-circular :size="70" :width="7" color="purple" indeterminate>
          加载中
        </v-progress-circular>
      </v-overlay>
      <v-snackbar v-model="$store.state.globalSnackbar.turn" vertical timeout="2000">
        {{ $store.state.globalSnackbar.msg }}

        <template v-slot:action="{ attrs }">
          <v-btn :color="$store.state.globalSnackbar.color" text v-bind="attrs"
            @click="$store.state.globalSnackbar.turn = false">
            关闭
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <Foot></Foot>
  </v-app>
</template>
<script>
  import Head from "@/components/Head";
  import Foot from "@/components/Foot";
  // import jwt from 'jwt-decode';
  import {
    getInfo
  } from "@/request/api/UserApi";
  export default {
    name: "app",
    components: {
      Head,
      Foot,
    },
    data() {
      return {
        loaded: false,
        name: ""
      }
    },
    methods: {
      logout() {
        window.location.replace(this.$globalConfig.ssoUrl + "/logout?redirect=" + window.location.href);
      }
    },
    beforeCreate() {
      //如果本地session有token 就过
      //如果本地session没有token 检查cookie有没有token
      //如果cookie有token 就check  
      //check中检查cookie中的token 如果不合格跳到login
      //如果合格就跳到check.html check.html中从cookie中取出token 存到session中 然后跳转回来
      if (!this.$cookieUtil.getCookie("verify") && !sessionStorage.getItem("verify")) {
        window.location.replace(this.$globalConfig.ssoUrl + "/chgcookie?redirect=" + window.location.href);
      } else {
        this.$cookieUtil.clearCookie("verify");
        sessionStorage.setItem("verify", "OK");
        getInfo().then(
          (data) => {
            if (data.code == 0) {
              this.$store.commit("updateUserInfo", data.data.data);
            } else {
              window.location.replace(this.$globalConfig.ssoUrl + "/logout?redirect=" + window.location.href);
            }
          }
        )
      }
    },
    watch: {
      "$store.state.roleLoaded": function () {
        this.loaded = this.$store.state.roleLoaded;
      },
      "$store.state.name": function () {
        this.name = this.$store.state.name;
      }
    }
  }
</script>
<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
</style>