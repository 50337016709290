<template>
    <v-dialog v-model="dialog" max-width="60rem">
        <v-card style="overflowY:auto;">
            <v-card-title>
                <span class="headline">发票详情</span>
            </v-card-title>
            <v-card-text v-if="loading" style="height:20rem;position:relative">
                <v-overlay absolute color="white">
                    <v-progress-circular :size="70" :width="3" color="purple" indeterminate>
                        加载中
                    </v-progress-circular>
                </v-overlay>
            </v-card-text>
            <v-card-text v-else>
                <v-container v-if="loaded">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="currentInvoice.userName" label="录入人" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="currentInvoice.createTime" label="录入时间" readonly></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="currentInvoice.invoiceNumber" label="发票号码" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="currentInvoice.invoiceCode" label="发票代码" readonly></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="6">
                            <v-text-field v-model="currentInvoice.invoiceName" label="发票名称" readonly></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="currentInvoice.invoiceTime" label="开票日期" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" v-for="(item,index) in currentInvoice.items" :key="index">
                            <v-card color="grey">
                                <v-card-title>
                                    条目{{index + 1}}
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field label="货物或应税劳务、服务名称" v-model="item.invoiceItemName" readonly>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="计量单位" v-model="item.invoiceItemUnit" readonly>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="数量" v-model="item.invoiceItemCount" readonly>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="单价" v-model="item.invoiceItemPrice" readonly>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="金额（不含税）" v-model="item.invoiceItemAmount" readonly>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="税率" v-model="item.invoiceItemTaxPercent" readonly>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="税额" v-model="item.invoiceItemTax" readonly>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="currentInvoice.invoiceAmount" label="发票金额（不含税）" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="currentInvoice.invoiceTax" label="税额" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field v-model="currentInvoice.invoiceAmountTotal" label="发票金额（含税）" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field label="购买方名称" v-model="currentInvoice.invoiceBuyerName" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field label="购买方识别号" v-model="currentInvoice.invoiceBuyerNumber" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field label="购买方联系方式" v-model="currentInvoice.invoiceBuyerContact" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field label="购买方开户行及账号" v-model="currentInvoice.invoiceBuyerBank" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field label="销售方名称" v-model="currentInvoice.invoiceProviderName" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field label="销售方识别号" v-model="currentInvoice.invoiceProviderNumber" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field label="销售方联系方式" v-model="currentInvoice.invoiceProviderContact" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field label="销售方开户行及账号" v-model="currentInvoice.invoiceProviderBank" readonly>
                            </v-text-field>
                        </v-col>
                        <!-- <v-col cols="4">
                            <v-text-field label="收款人" v-model="currentInvoice.invoicePayee" readonly></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="复核人" v-model="currentInvoice.invoiceReviewer" readonly></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="开票人" v-model="currentInvoice.invoiceDrawer" readonly></v-text-field>
                        </v-col> -->
                        <v-col cols="12">
                            <v-text-field label="备注" v-model="currentInvoice.invoiceRemarks" readonly>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="images" v-viewer>
                                <img :src="currentInvoice.imageUrl" style="maxHeight:10rem" />
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                    关闭
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {
        getInvoiceDetail
    } from "@/request/api/AccountApi";
    export default {
        name: "invoicedetail",
        data() {
            return {
                loading: false,
                dialog: false,
                invoiceId: 0,
                currentInvoice: null,
                loaded: false
            }
        },
        methods: {
            refreshData(invoiceId) {
                this.loading = true;
                this.dialog = true;
                this.invoiceId = invoiceId;
                getInvoiceDetail(this.invoiceId).then(
                    (data) => {
                        if (data.code == 0) {
                            this.currentInvoice = data.data.data;
                        } else {
                            this.$store.commit("showSnackBar", {
                                msg: "加载失败",
                                color: "red"
                            });
                        }
                        this.loading = false;
                        this.loaded = true;
                    }
                )
            }
        }
    }
</script>