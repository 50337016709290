<template>
    <v-container>
        <v-data-table :headers="headers" hide-default-footer disable-filtering disable-pagination disable-sort
            :items="desserts" no-data-text="暂无数据" :loading="loading" loading-text="加载中" class="elevation-1 pt-2">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-btn :color="currentCompanyId == 0 ? 'primary' : 'grey'" dark class="mb-2"
                        @click="currentCompanyId = 0">
                        全部
                    </v-btn>
                    <v-btn v-for="(item,index) in companys" :key="index"
                        :color="currentCompanyId == item.companyId ? 'primary':'grey'" dark class="ml-2 mb-2"
                        @click="currentCompanyId = item.companyId">
                        {{item.companyName}}
                    </v-btn>
                </v-toolbar>
                <v-container class="ma-0 pa-0">
                    <v-row>
                        <v-col cols="6" sm="2" offset-sm="7">
                            <v-select :items="searchType" item-text="name" item-value="key" v-model="currentSearchType"
                                label="搜索类型" dense></v-select>
                        </v-col>
                        <v-col cols="6" sm="2">
                            <v-text-field placeholder="搜索内容" @keyup.enter="search" v-model="currentSearchStr" filled
                                rounded dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:item.invoiceBuyerName="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            {{!item.invoiceBuyerName ? "" : item.invoiceBuyerName.length > 15 ? item.invoiceBuyerName.slice(0,15) + "..." : item.invoiceBuyerName}}
                        </span>
                    </template>
                    <span>{{item.invoiceBuyerName}}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.invoiceItems="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            {{!item.invoiceItems ? "" : item.invoiceItems.length > 10 ? item.invoiceItems.slice(0,10) + "..." : item.invoiceItems}}
                        </span>
                    </template>
                    <span>{{item.invoiceItems}}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn fab x-small elevation="0" class="mr-2" @click="$refs.detail.refreshData(item.invoiceId)"
                    color="primary">
                    <v-icon small>
                        mdi-eye
                    </v-icon>
                </v-btn>
                <v-btn fab x-small elevation="0" color="red" @click="deleteItem(item)" v-if="$hasRole('removeInvoice')">
                    <v-icon small>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-pagination v-model="page" :length="refreshTotalPage()" :total-visible="7"></v-pagination>
        <Detail ref="detail"></Detail>
        <ConfirmDialog ref="confirm"></ConfirmDialog>
    </v-container>
</template>

<script>
    import {
        getAllCompany
    } from "@/request/api/UserApi";
    import {
        getInvoiceInfos,
        removeInvoiceById
    } from "@/request/api/AccountApi";
    import ConfirmDialog from "@/components/ConfirmDialog";
    import Detail from "@/components/InvoiceDetails";
    export default {
        name: "showinvoice",
        components: {
            Detail,
            ConfirmDialog
        },
        data() {
            return {
                loading: false,
                companys: [],
                currentCompanyId: 0,
                searchType: [{
                        key: 1,
                        name: "购买方名称"
                    },
                    {
                        key: 2,
                        name: "品名"
                    },
                    {
                        key: 3,
                        name: "发票金额"
                    }, {
                        key: 4,
                        name: "开票日期"
                    }
                ],
                currentSearchType: 1,
                currentSearchStr: "",
                isSearching: false,
                headers: [{
                    text: '编号',
                    align: 'center',
                    sortable: false,
                    value: 'invoiceId',
                }, {
                    text: '发票编号',
                    align: 'center',
                    sortable: false,
                    value: 'invoiceNumber',
                }, {
                    text: '购买方名称',
                    align: 'center',
                    sortable: false,
                    value: 'invoiceBuyerName',
                }, {
                    text: '品名',
                    align: 'center',
                    sortable: false,
                    value: 'invoiceItems',
                }, {
                    text: '发票金额',
                    align: 'center',
                    sortable: false,
                    value: 'invoiceAmountTotal',
                }, {
                    text: '开票日期',
                    align: 'center',
                    sortable: false,
                    value: 'invoiceDate',
                }, {
                    text: '操作',
                    align: 'center',
                    sortable: false,
                    value: 'actions',
                }],
                desserts: [],
                total: 0,
                page: 1,
                pageCount: 15,
                currentDeleteItem: null
            }
        },
        methods: {
            refreshData() {
                let params = {
                    page: this.page,
                    com: this.currentCompanyId
                };
                if (this.isSearching) {
                    params = {
                        page: this.page,
                        com: this.currentCompanyId,
                        type: this.currentSearchType,
                        query: this.currentSearchStr
                    };
                }
                getInvoiceInfos(params).then((data) => {
                    this.loading = false;
                    if (data.code == 0) {
                        this.total = data.data.total;
                        this.desserts = data.data.data;
                    } else {
                        this.$store.commit("showSnackBar", {
                            msg: data.message,
                            color: "orange"
                        });
                    }
                })
            },
            search() {
                if (this.currentSearchStr) {
                    this.isSearching = true;
                    this.page = 1;
                    this.desserts = [];
                    this.refreshData();
                } else {
                    this.$store.commit("showSnackBar", {
                        msg: "请输入搜索条件"
                    })
                }
            },
            refreshTotalPage() {
                return Number.parseInt(this.total / this.pageCount) + ((this.total % this.pageCount) == 0 ? 0 : 1);
            },
            confirm() {
                if (this.currentDeleteItem) {
                    removeInvoiceById(this.currentDeleteItem.invoiceId).then(
                        (data) => {
                            if (data.code == 0) {
                                this.$store.commit("showSnackBar", {
                                    msg: "删除成功"
                                })
                                this.refreshData();
                            } else {
                                this.$store.commit("showSnackBar", {
                                    msg: data.message
                                })
                            }
                        }
                    )
                }
            },
            deleteItem(invoice) {
                this.currentDeleteItem = invoice;
                this.$refs.confirm.show();
            }
        },
        mounted() {
            this.loading = true;
            getAllCompany().then((data) => {
                if (data.code == 0) {
                    this.companys = data.data.data
                }
            });
            this.refreshData();
        },
        watch: {
            "currentCompanyId": function () {
                this.desserts = [];
                this.page = 1;
                this.isSearching = false;
                this.currentSearchStr = "";
                this.refreshData();
            },
            "page": function () {
                this.loading = true;
                this.desserts = [];
                this.refreshData();
            }
        }
    }
</script>