<template>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title>确认</v-card-title>
            <v-card-text>
                <p class="font-weight-black">
                    {{dialogMsg}}
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="cancel()">
                    取消
                </v-btn>
                <v-btn color="primary darken-1" text @click="confirm()">
                    确认
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "confirmdialog",
        data() {
            return {
                dialogMsg: "",
                dialog: false
            }
        },
        methods: {
            show(msg) {
                if (msg) {
                    this.dialogMsg = msg;
                } else {
                    this.dialogMsg = "确认要删除吗？";
                }
                this.dialog = true;
            },
            confirm() {
                this.dialog = false;
                if (this.$parent && this.$parent.confirm) {
                    this.$parent.confirm();
                }
            },
            cancel() {
                this.dialog = false;
                if (this.$parent && this.$parent.cancel) {
                    this.$parent.cancel();
                }
            }
        }
    }
</script>