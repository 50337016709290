<template>
    <div>
        <v-container class="d-none d-sm-flex ma-0" v-if="!isIE">
            <v-row no-gutters>
                <v-col cols="10">
                    <div width="100%" id='ActiveXDivOne'>
                    </div>
                </v-col>
                <v-col cols="2">
                    <v-row no-gutters>
                        <v-col cols="12" class="mt-12">
                            <v-btn class="primary" @click="capture">
                                <v-icon>
                                    mdi-camera
                                </v-icon>
                                拍照
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="d-flex d-sm-none" v-else>
            <v-row class="text-center">
                <v-col cols="12">
                    请使用其他浏览器打开（推荐chrome）
                </v-col>
            </v-row>
        </v-container>
        <v-container class="d-flex d-sm-none">
            <v-row class="text-center">
                <v-col cols="12">
                    请使用电脑浏览器打开
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="dialog" persistent max-width="60rem">
            <v-card>
                <v-card-title>识别结果</v-card-title>
                <v-card-text v-if="loaded">
                    <v-row>
                        <v-col cols="12">
                            <v-col cols="12">
                                <v-select :items="companys" v-model="currentCompany" item-text="companyName"
                                    item-value="companyId" label="收票公司" no-data-text="没有数据"></v-select>
                            </v-col>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="发票号码" v-model="InvoiceOcr.invoiceNumber"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="发票代码" v-model="InvoiceOcr.invoiceCode"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="发票名称" v-model="InvoiceOcr.invoiceName"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="开票日期" v-model="InvoiceOcr.invoiceTime"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-for="(item,index) in InvoiceOcr.items" :key="index">
                            <v-card color="grey">
                                <v-card-title>
                                    条目{{index + 1}}
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field label="货物或应税劳务、服务名称" v-model="item.invoiceItemName">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="计量单位" v-model="item.invoiceItemUnit"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="数量" v-model="item.invoiceItemCount"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="单价" v-model="item.invoiceItemPrice"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="金额（不含税）" v-model="item.invoiceItemAmount">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="税率" v-model="item.invoiceItemTaxPercent">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="税额" v-model="item.invoiceItemTax"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="发票金额（不含税）" v-model="InvoiceOcr.invoiceAmount"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="税额" v-model="InvoiceOcr.invoiceTax"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="发票金额（含税）" v-model="InvoiceOcr.invoiceAmountTotal"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="购买方名称" v-model="InvoiceOcr.invoiceBuyerName"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="购买方识别号" v-model="InvoiceOcr.invoiceBuyerNumber"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="购买方联系方式" v-model="InvoiceOcr.invoiceBuyerContact"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="购买方开户行及账号" v-model="InvoiceOcr.invoiceBuyerBank"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="销售方名称" v-model="InvoiceOcr.invoiceProviderName"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="销售方识别号" v-model="InvoiceOcr.invoiceProviderNumber"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="销售方联系方式" v-model="InvoiceOcr.invoiceProviderContact"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="销售方开户行及账号" v-model="InvoiceOcr.invoiceProviderBank"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="收款人" v-model="InvoiceOcr.invoicePayee"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="复核人" v-model="InvoiceOcr.invoiceReviewer"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field label="开票人" v-model="InvoiceOcr.invoiceDrawer"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="备注" v-model="InvoiceOcr.invoiceRemarks"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="images" v-viewer>
                                <img :src="captureUrl[0]" style="maxHeight:10rem" />
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else style="minHeight:15rem">
                    <v-overlay absolute v-if="loadedoverlay">
                        <v-progress-circular :size="70" :width="4" color="white" indeterminate>
                            识别中
                        </v-progress-circular>
                    </v-overlay>
                    <v-container v-else>
                        <v-row>
                            <v-col class="text-center" cols="12">
                                识别失败
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="reOcrCapture">重新识别</v-btn>
                    <v-btn color="primary" text @click="cancel">取消</v-btn>
                    <v-btn color="primary" v-if="loaded" text @click="submit">提交</v-btn>
                </v-card-actions>
            </v-card>
            <v-overlay absolute :value="submitoverlay">
                <v-progress-circular :size="70" :width="4" color="white" indeterminate>
                    上传中
                </v-progress-circular>
            </v-overlay>
        </v-dialog>
        <Overlay ref="overlay"></Overlay>
    </div>
</template>

<script>
    import {
        uploadImageBase64
    } from "@/request/api/FileApi";
    import {
        scanInovice,
        addNewInvoice
    } from "@/request/api/AccountApi";
    import {
        getUserCompany
    } from "@/request/api/UserApi";
    import Overlay from '../components/Overlay.vue';
    export default {
        name: "newinvoice",
        components: {
            Overlay
        },
        data() {
            return {
                isIE: false,
                dialog: false,
                deviceResolution: [],
                captureUrl: [],
                captureImageName: "",
                loaded: false,
                loadedoverlay: false,
                submitoverlay: false,
                InvoiceOcr: {
                    invoiceNumber: "",
                    invoiceCode: "",
                    invoiceName: "",
                    invoiceTime: "",
                    invoiceAmount: "",
                    invoiceTax: "",
                    invoiceAmountTotal: "",
                    invoiceBuyerName: "",
                    invoiceBuyerNumber: "",
                    invoiceBuyerContact: "",
                    invoiceBuyerBank: "",
                    invoiceProviderName: "",
                    invoiceProviderNumber: "",
                    invoiceProviderContact: "",
                    invoiceProviderBank: "",
                    invoicePayee: "",
                    invoiceReviewer: "",
                    invoiceDrawer: "",
                    invoiceRemarks:"",
                    items: []
                },
                companys: [],
                currentCompany: 0,
            }
        },
        methods: {
            initParam() {
                window.isIE = false;
                window.isSecondDev = false;
                window.camidMain = 0;
                window.camidSub = 0;
                window.GetDevName = this.GetDevName;
                window.GetDeviceResolution = this.GetDeviceResolution;
                window.LoadOver = this.LoadOver;
                window.InfoCallback = this.InfoCallback;
                window.InfoTextCallback = this.InfoTextCallback;
            },
            GetDevName(num, strUsbNamr) {
                console.log("number:" + num);
                console.log("usbname:" + strUsbNamr);
            },
            GetDeviceResolution(data) {
                var max = 0;
                if (data.length > 0) {
                    for (var i = 0; i < data.length / 2; i++) {
                        var ww = data[i * 2];
                        var hh = data[i * 2 + 1];
                        this.deviceResolution.push("" + ww + "*" + hh);
                        if (max < ww) {
                            max = ww;
                        }
                    }
                }
                console.log(this.deviceResolution);
            },
            LoadOver() {
                this.StartVideo();
            },
            StartVideo() {
                window.SetImageType(1);
                window.SetColorMode(0);
                window.SetImagebase64(1);
                window.StartCam(window.camidMain, 2592, 1944);
            },
            InfoCallback(op) {
                var text = "";
                if (op == 0) {
                    text = "连接成功\r\n";
                } else if (op == 0x01) {
                    text = "断开成功\r\n";
                } else if (op == 0x02) {
                    text = "设备已经连接\r\n";
                } else if (op == 0x03) {
                    text = "设备已经关闭\r\n";
                    this.$refs.overlay.overlay = false;
                    this.$store.commit("showSnackBar", {
                        msg: "拍照失败，请确认设备是否正确连接"
                    });
                } else if (op == 0x04) {
                    text = "拍照成功\r\n";
                } else if (op == 0x05) {
                    text = "pdf添加文件成功\r\n";
                } else if (op == 0x06) {
                    text = "pdf保存成功\r\n";
                } else if (op == 0x07) {
                    text = "图片合并成功\r\n";
                } else if (op == 0x08) {
                    text = "智能连拍启动\r\n";
                } else if (op == 0x09) {
                    text = "定时连拍启动\r\n";
                } else if (op == 0x10) {
                    text = "定时连拍成功\r\n";
                } else if (op == 0x11) {
                    text = "定时连拍关闭\r\n";
                } else if (op == 0x12) {
                    text = "文件上传服务器成功\r\n";
                } else if (op == 0x13) {
                    text = "水印开启\r\n";
                } else if (op == 0x14) {
                    text = "水印关闭\r\n";
                } else if (op == 0x15) {
                    text = "此设备属于本公司\r\n";
                } else if (op == 0x16) {
                    text = "此设备不属于本公司\r\n";
                } else if (op == 0x17) {
                    text = "自动曝光启动\r\n";
                } else if (op == 0x18) {
                    text = "自动曝光关闭\r\n";
                } else if (op == 0x19) {
                    text = "身份证功能启动成功\r\n";
                } else if (op == 0x1a) {
                    text = "身份证功能启动失败\r\n";
                } else if (op == 0x1b) {
                    text = "身份证读卡成功\r\n";
                } else if (op == 0x1c) {
                    text = "身份证读卡失败\r\n";
                } else if (op == 0x1d) {
                    text = "重新操作\r\n";
                } else if (op == 0x1e) {
                    text = "未发现模块\r\n";
                } else if (op == 0x1f) {
                    text = "未启动身份证功能\r\n";
                } else if (op == 0x20) {
                    text = "启动身份证自动读卡\r\n";
                } else if (op == 0x21) {
                    text = "关闭身份证自动读卡\r\n";
                } else if (op == 0x22) {
                    text = "启动拍照只生成base64\r\n";
                } else if (op == 0x23) {
                    text = "关闭拍照只生成base64\r\n";
                } else if (op == 0x25) {
                    text = "初始化指纹模块成功\r\n";
                } else if (op == 0x26) {
                    text = "初始化指纹模块失败\r\n";
                } else if (op == 0x27) {
                    text = "未初始化指纹模块\r\n";
                } else if (op == 0x28) {
                    text = "身份证没有指纹数据\r\n";
                } else if (op == 0x29) {
                    text = "指纹认证成功\r\n";
                } else if (op == 0x30) {
                    text = "开始指纹认证\r\n";
                } else if (op == 0x31) {
                    text = "正在指纹认证\r\n";
                } else if (op == 0x32) {
                    text = "停止指纹认证\r\n";
                } else if (op == 0x33) {
                    text = "指纹认证失败\r\n";
                } else if (op == 0x34) {
                    text = "开始录像\r\n";
                } else if (op == 0x35) {
                    text = "停止录像\r\n";
                } else if (op == 0x36) {
                    text = "正在录像中\r\n";
                } else if (op == 0x37) {
                    text = "开始录像副头\r\n";
                } else if (op == 0x38) {
                    text = "停止录像副头\r\n";
                } else if (op == 0x39) {
                    text = "正在录像中副头\r\n";
                } else if (op == 0x44) {
                    text = "建立文件成功\r\n";
                } else if (op == 0x45) {
                    text = "建立文件失败\r\n";
                } else if (op == 0x46) {
                    text = "人脸识别初始化成功\r\n";
                } else if (op == 0x47) {
                    text = "启动人脸对比\r\n";
                } else if (op == 0x48) {
                    text = "人脸识别初始化成功\r\n";
                } else if (op == 0x49) {
                    text = "主头正在连接中\r\n";
                } else if (op == 0x4a) {
                    text = "主头等待连接\r\n";
                } else if (op == 0x4b) {
                    text = "副头正在连接中\r\n";
                } else if (op == 0x4c) {
                    text = "副头等待连接\r\n";
                } else if (op == 0xa0) {
                    text = "没有对应分辨率\r\n";
                } else if (op == 0xa1) {
                    text = "pdf没有添加任何文件\r\n";
                } else if (op == 0xa2) {
                    text = "文件不存在\r\n";
                } else if (op == 0xa3) {
                    text = "意外断开\r\n";
                } else if (op == 0xa4) {
                    text = "连接不上\r\n";
                } else if (op == 0xa5) {
                    text = "pdf添加文件不是jpg格式\r\n";
                } else if (op == 0xa6) {
                    text = "没有发现摄像头\r\n";
                } else if (op == 0xa7) {
                    text = "camid无效\r\n";
                } else if (op == 0xa8) {
                    text = "图片尺寸太小\r\n";
                } else if (op == 0xa9) {
                    text = "文件上传服务器失败\r\n";
                } else if (op == 0xaa) {
                    text = "该设备没有副头\r\n";
                } else if (op == 0xab) {
                    text = "条码识别失败\r\n";
                } else if (op == 0xac) {
                    text = "二维码识别失败\r\n";
                } else if (op == 0xad) {
                    text = "图片合并失败\r\n";
                } else if (op == 0xae) {
                    text = "设置路径失败,路径不存在\r\n";
                } else if (op == 0xaf) {
                    text = "摄像头切换太频繁\r\n";
                } else if (op == 0xb1) {
                    text = "未发现指纹模块\r\n";
                } else if (op == 0xb2) {
                    text = "录像分辨率不能高于1600*1200\r\n";
                } else if (op == 0xb3) {
                    text = "副头录像分辨率不能高于1600*1200\r\n";
                } else if (op == 0xb4) {
                    text = "没发现麦克风\r\n";
                } else if (op == 0xb8) {
                    text = "人脸识别初始化失败\r\n";
                } else if (op == 0xb9) {
                    text = "请读取身份证信息\r\n";
                } else if (op == 0xba) {
                    text = "请先初始化人脸识别\r\n";
                } else if (op == 0xbb) {
                    text = "没有发现合适的人脸\r\n";
                }
                console.log("op:" + op + "  text:" + text);
            },
            InfoTextCallback(type, str) {
                if (type == 0) {
                    //拍照回调，str图片全路径
                    console.log(str);
                    //上传图片
                    //window.UpdataFile("127.0.0.1", 9900, "/image", str);
                    //回调后删除图片
                } else if (type == 5) {
                    //拍照回调，str图片base64
                    this.$refs.overlay.overlayText = "图片上传中";
                    uploadImageBase64({
                        base64Str: encodeURIComponent(str)
                    }).then(
                        (data) => {
                            this.$refs.overlay.overlay = false;
                            if (data.code == 0) {
                                //记录返回来的图片url
                                this.captureUrl = [];
                                this.captureUrl.push(data.data.data);
                                this.captureImageName = data.data.ImageName;
                                //调用图片识别接口
                                this.dialog = true;
                                this.ocrCapture();
                            } else {
                                this.$store.commit("showSnackBar", {
                                    msg: "图片上传失败，请重新尝试"
                                });
                            }
                        }
                    ).catch(() => {
                        this.$refs.overlay.overlay = false;
                        this.$store.commit("showSnackBar", {
                            msg: "图片上传失败，请重新尝试"
                        });
                    })
                } else {
                    this.$refs.overlay.overlay = false;
                    this.$store.commit("showSnackBar", {
                        msg: "拍照失败，请确认设备是否正确连接"
                    });
                    return;
                }
            },
            capture() {
                window.CaptureImage(1);
                this.$refs.overlay.overlayText = "拍照中，请稍等";
                this.$refs.overlay.overlay = true;
            },
            ocrCapture() {
                this.loadedoverlay = true;
                this.loaded = false;
                scanInovice({
                    url: this.captureUrl[0]
                }).then((data) => {
                    if (data.code == 0) {
                        this.loaded = true;
                        this.InvoiceOcr = data.data.data;
                    } else {
                        this.loadedoverlay = false;
                    }
                }).catch(() => {
                    this.loadedoverlay = false;
                })
            },
            reOcrCapture() {
                this.InvoiceOcr = {
                    invoiceNumber: "",
                    invoiceCode: "",
                    invoiceName: "",
                    invoiceTime: "",
                    invoiceAmount: "",
                    invoiceTax: "",
                    invoiceAmountTotal: "",
                    invoiceBuyerName: "",
                    invoiceBuyerNumber: "",
                    invoiceBuyerContact: "",
                    invoiceBuyerBank: "",
                    invoiceProviderName: "",
                    invoiceProviderNumber: "",
                    invoiceProviderContact: "",
                    invoiceProviderBank: "",
                    invoicePayee: "",
                    invoiceReviewer: "",
                    invoiceDrawer: "",
                    items: []
                };
                this.ocrCapture();
            },
            cancel() {
                this.InvoiceOcr = {
                    invoiceNumber: "",
                    invoiceCode: "",
                    invoiceName: "",
                    invoiceTime: "",
                    invoiceAmount: "",
                    invoiceTax: "",
                    invoiceAmountTotal: "",
                    invoiceBuyerName: "",
                    invoiceBuyerNumber: "",
                    invoiceBuyerContact: "",
                    invoiceBuyerBank: "",
                    invoiceProviderName: "",
                    invoiceProviderNumber: "",
                    invoiceProviderContact: "",
                    invoiceProviderBank: "",
                    invoicePayee: "",
                    invoiceReviewer: "",
                    invoiceDrawer: "",
                    items: []
                };
                this.captureUrl = [];
                this.captureImageName = "";
                this.dialog = false;
            },
            submit() {
                if (this.loaded) {
                    this.submitoverlay = true;
                    this.InvoiceOcr.imageId = this.captureImageName;
                    this.InvoiceOcr.companyId = this.currentCompany;
                    addNewInvoice(this.InvoiceOcr).then((data) => {
                        this.submitoverlay = false;
                        if (data.code == 0 && data.data.success) {
                            this.cancel();
                            this.$store.commit("showSnackBar", {
                                msg: "上传成功"
                            });
                        } else {
                            this.$store.commit("showSnackBar", {
                                msg: "上传失败，请稍后重新尝试",
                                color: "red"
                            });
                        }
                    }).catch(() => {
                        this.submitoverlay = false;
                        this.$store.commit("showSnackBar", {
                            msg: "上传失败，服务器错误，请稍后再次尝试",
                            color: "red"
                        });
                    })
                }
            }
        },
        created() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                this.isIE = true;
            } else {
                this.isIE = false;
                this.initParam();
                if (!window.WebSocket) {
                    alert("WebSocket not supported by this browser!");
                }
            }
        },
        mounted() {
            if (!this.isIE) {
                document.getElementById("ActiveXDivOne").innerHTML =
                    " <canvas id=\"cameraCanvas\" width=\"800px\" height=\"600px\" style=\"border:1px solid #d3d3d3;\">";
                window.WsInit(800, 600, 0, 0, true);
                getUserCompany().then(
                    (data) => {
                        if (data.code == 0) {
                            this.companys = data.data.data;
                            if (this.companys && this.companys.length > 0) {
                                this.currentCompany = this.companys[0].companyId;
                            }
                        }
                    }
                )
            }
        },
        beforeDestroy() {
            if (window.StopICWork) {
                window.StopICWork();
            }
            if (window.CloseFinger) {
                window.CloseFinger();
            }
            if (window.CloseCam) {
                window.CloseCam();
            }
            if (window.FaceCheckUninit) {
                window.FaceCheckUninit();
            }
        }
    }
</script>