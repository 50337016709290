import service from './Request'

const http = {
    get(url, params) {
        const config = {
            method: 'get',
            url: url
        }
        if (params) {
            config.params = params
        }
        return service(config)
    },
    post(url, params) {
        const config = {
            method: 'post',
            url: url,
            data: undefined
        }
        if (params) config.data = params
        return service(config)
    },
    put(url, params) {
        const config = {
            method: 'put',
            url: url
        }
        if (params) config.params = params
        return service(config)
    },
    putBody(url, params) {
        const config = {
            method: 'put',
            url: url
        }
        if (params) config.data = params
        return service(config)
    },
    delete(url, params) {
        const config = {
            method: 'delete',
            url: url
        }
        if (params) config.params = params
        return service(config)
    },
    deleteBody(url, params) {
        const config = {
            method: "delete",
            url: url
        }
        if (params) config.data = params
        return service(config)
    },
    upload(url, params) {
        const config = {
            method: 'post',
            url: url,
            data: undefined,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        if (params) config.data = params
        return service(config)
    }
}

export default http