const btnRoles = [{
    name: "removeAccount",
    roleId: 17,
    desc: "删除账单按钮"
}, {
    name: "removeDefaultSms",
    roleId: 20,
    desc: "删除默认通知"
}, {
    name: "addDefaultSms",
    roleId: 19,
    desc: "新增默认通知"
}, {
    name: "removeInvoice",
    roleId: 23,
    desc: "删除发票按钮"
}];

export default btnRoles;