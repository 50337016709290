<template>
    <v-navigation-drawer v-model="drawer" app>
        <v-card class="mx-auto text-left" tile flat>
            <v-list>
                <v-list-item-group color="primary">
                    <v-list-item v-for="(item, i) in roles" :key="i" :to="item.roleRouter">
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.roleName"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
        <v-overlay absolute :value="overlay">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate>
                加载中
            </v-progress-circular>
        </v-overlay>
    </v-navigation-drawer>
</template>
<script>
    import {
        getRole
    } from '@/request/api/UserApi'
    export default {
        name: "heade",
        data() {
            return {
                drawer: true,
                overlay: false,
                roles: []
            }
        },
        mounted() {
            this.overlay = true;
            getRole()
                .then(
                    (data) => {
                        if (data.code == 0) {
                            this.$store.commit("refreshRoles", data.data.data);
                        } else {
                            this.$store.commit("refreshRoles", []);
                        }
                        this.overlay = false;
                    }
                )
                .catch(
                    () => {
                        this.$store.commit("refreshRoles", []);
                        this.overlay = false;
                    }
                )
        },
        watch: {
            "$store.state.roles": function () {
                this.roles = [{
                    applicationId: 0,
                    btnRole: false,
                    icon: "mdi-home",
                    roleDesc: null,
                    roleId: 0,
                    roleName: "首页",
                    roleRouter: "/",
                    roleRouterName: "首页",
                }];
                for (let i = 0; i < this.$store.state.roles.length; i++) {
                    let temp = this.$store.state.roles[i];
                    this.roles.push(temp);
                }

            }
        }
    }
</script>