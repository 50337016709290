<template>
    <v-overlay absolute :value="overlay">
        <v-progress-circular :size="70" :width="7" color="purple" indeterminate>
            {{overlayText}}
        </v-progress-circular>
    </v-overlay>
</template>
<script>
    export default {
        name: "joverlay",
        data() {
            return {
                overlay: false,
                overlayText: "加载中"
            }
        }
    }
</script>