import axios from 'axios'
import store from '../store/index'
import Vue from 'vue'

const service = axios.create({
    //请求超时时间
    timeout: 30 * 1000,
    // baseURL: "http://111.75.249.68:8889"
    baseURL: "http://api.jxglyy.net/api",
    withCredentials: true
})

//请求拦截器
service.interceptors.request.use(
    (config) => {
        if (!config.headers['Content-Type']) {
            config.headers = {
                'Content-Type': 'application/json'
            }
        }
        return config
    },
    (error) => {
        Promise.reject(error);
    }
)

//响应拦截器
service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
            case 400:
                error.message = '错误请求'
                break;
            case 401:
                window.location.replace(Vue.prototype.$globalConfig.ssoUrl + "/chgcookie?redirect=" + window.location.href);
                break;
            case 403:
                store.commit("showSnackBar", {
                    msg: "没有权限",
                    color: "red"
                });
                break;
            case 404:
                error.message = '请求错误,未找到该资源'
                break;
            case 405:
                error.message = '请求方法未允许'
                break;
            case 408:
                error.message = '请求超时'
                break;
            case 500:
                error.message = '服务器端出错'
                break;
            case 501:
                error.message = '网络未实现'
                break;
            case 502:
                error.message = '网络错误'
                break;
            case 503:
                error.message = '服务不可用'
                break;
            case 504:
                error.message = '网络超时'
                break;
            case 505:
                error.message = 'http版本不支持该请求'
                break;
            default:
                error.message = `连接错误`
        }
        return Promise.resolve(error.message);
    }
)

export default service