<template>
    <v-dialog v-model="dialog" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">账单详情</span>
            </v-card-title>
            <v-card-text v-if="loading" style="height:20rem;position:relative">
                <v-overlay absolute color="white">
                    <v-progress-circular :size="70" :width="3" color="purple" indeterminate>
                        加载中
                    </v-progress-circular>
                </v-overlay>
            </v-card-text>
            <v-card-text v-else>
                <v-container>
                    <v-row class="text-left">
                        <v-col cols="12" sm="2" class="text-right">
                            <span>编号: </span>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <span><b>{{currentAccount ? currentAccount.accountId : ""}}</b></span>
                        </v-col>
                        <v-col cols="12" sm="2" class="text-right">
                            <span>客户名称: </span>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <span><b>{{currentAccount ? currentAccount.customerName : ""}}</b></span>
                        </v-col>
                        <v-col cols="12" sm="2" class="text-right">
                            <span>收款金额: </span>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <span><b>{{currentAccount ? currentAccount.customerAmount : ""}}</b></span>
                        </v-col>
                        <v-col cols="12" sm="2" class="text-right">
                            <span>录入人: </span>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <span><b>{{currentAccount ? currentAccount.userName : ""}}</b></span>
                        </v-col>
                        <v-col cols="12" sm="2" class="text-right">
                            <span>录入时间: </span>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <span><b>{{currentAccount ? currentAccount.createTime : ""}}</b></span>
                        </v-col>
                        <v-col cols="12" sm="2" class="text-right">
                            <span>短信(微信)通知: </span>
                        </v-col>
                        <v-col cols="12" sm="10" v-if="currentAccount">
                            <div v-for="(item,index) in currentAccount.sms" :key="index">
                                <span>{{item.name}}--{{item.phone}}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                    关闭
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {
        getAccountDetail
    } from "@/request/api/AccountApi";
    export default {
        name: "accountdetail",
        data() {
            return {
                loading: false,
                dialog: false,
                accountId: 0,
                currentAccount: null
            }
        },
        methods: {
            refreshData(accountId) {
                this.loading = true;
                this.dialog = true;
                this.accountId = accountId;
                getAccountDetail(this.accountId).then(
                    (data) => {
                        if (data.code == 0) {
                            this.currentAccount = data.data.data;
                        } else {
                            this.$store.commit("showSnackBar", {
                                msg: "加载失败",
                                color: "red"
                            });
                        }
                        this.loading = false;
                    }
                )
            }
        }
    }
</script>