import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      name: "首页"
    }
  }, {
    path: '/account/view',
    name: 'account',
    component: () => import("@/views/Account.vue"),
    meta: {
      roleId: 15,
      name: "历史来款"
    }
  }, {
    path: '/account/new',
    name: 'accountnew',
    component: () => import("@/views/NewAccount.vue"),
    meta: {
      roleId: 16,
      name: "新增来款"
    }
  }, {
    path: '/sms/view',
    name: 'defaultsms',
    component: () => import("@/views/DefaultSms.vue"),
    meta: {
      roleId: 18,
      name: "默认通知"
    }
  }, {
    path: '/invoice/view',
    name: 'ViewInvoice',
    component: () => import("@/views/Invoice.vue"),
    meta: {
      roleId: 21,
      name: "历史发票"
    }
  }, {
    path: '/invoice/new',
    name: "NewInvoice",
    component: () => import("@/views/NewInvoice.vue"),
    meta: {
      roleId: 22,
      name: "新增发票"
    }
  },
  {
    path: "/404",
    name: "notFound",
  }, {
    path: "*",
    redirect: "/404"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === "Home") {
    next();
    return;
  }
  if (to.name === "notFound") {
    store.commit("showSnackBar", {
      msg: "页面未找到",
      color: "orange"
    });
    if (!(from.name === "Home")) {
      router.push("/");
    }
    return;
  }
  if (!store.state.roleLoaded) {
    setTimeout(() => {
      router.push(to);
    }, 100);
    return;
  }
  if (!store.state.roles && store.state.roles.length <= 0) {
    store.commit("showSnackBar", {
      msg: "没有权限",
      color: "red"
    });
    return;
  } else {
    if (checkRole(to.meta.roleId)) {
      next();
    } else {
      store.commit("showSnackBar", {
        msg: "没有权限",
        color: "red"
      });
      return;
    }
  }
})


function checkRole(requestRole) {
  if (store.state.roles) {
    var role = store.state.roles.find(f => f.roleId == requestRole);
    if (role) {
      return true;
    }
  }
  return false;
}

export default router