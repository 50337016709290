<template>
  <v-container>
    <v-file-input @change="change" v-model="file"></v-file-input>
    <v-btn @click="submit">上传</v-btn>
  </v-container>
</template>

<script>
  import {
    uploadWordImage
  } from "@/request/api/AccountApi";
  export default {
    name: 'Home',
    data() {
      return {
        file: null
      }
    },
    methods: {
      change() {
        console.log(this.file)
      },
      submit() {
        let params = new FormData();
        params.append("file", this.file);
        uploadWordImage(params).then((data) => {
          console.log(data);
        })
      }
    }
  }
</script>