<template>
    <v-container>
        <v-form v-model="valid" lazy-validation ref="accountForm">
            <v-card class="ma-4 pa-4" flat>
                <v-card-actions>
                    <v-spacer>
                    </v-spacer>
                    <v-btn color="primary" @click="submitNewAccount">
                        提交
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-container>
                        <v-row class="text-left">
                            <v-col cols="12" md="6">
                                <v-text-field v-model="accountInfo.customerName" :rules="rules.nameRules" label="客户名称"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="accountInfo.customerAmount" :rules="rules.amountRules"
                                    label="收款金额(保留两位小数)" required>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                    :return-value.sync="accountInfo.date" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="accountInfo.date" label="收款日期"
                                            prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="accountInfo.date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(accountInfo.date)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12">
                                <v-select :items="companys" v-model="currentCompany" item-text="companyName"
                                    item-value="companyId" label="收账公司" no-data-text="没有数据"></v-select>
                            </v-col>
                            <v-col cols="12" sm="2">
                                短信(微信)通知：
                            </v-col>
                            <v-col cols="12" sm="10">
                                <v-row>
                                    <v-col cols="12" v-for="(item,index) in defaultSms" :key="index">
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="item.name" label="姓名" disabled>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="item.phone" label="手机号" disabled>
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" v-for="(item,index) in accountInfo.sms" :key="item.phone">
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="item.name" label="姓名" readonly>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field v-model="item.phone" label="手机号" readonly>
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn @click="removePhone(index)">
                                                    删除
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-spacer>
                                        </v-spacer>
                                        <v-btn @click="openDialog" large fab color="cyan" style="marginLeft:auto">
                                            <v-icon>
                                                mdi-plus
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-form>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span>
                        新增通知
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form lazy-validation v-model="phoneValid" ref="phoneForm">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field prepend-inner-icon="mdi-magnify" dense flat hide-details rounded
                                        solo-inverted placeholder="输入姓名搜索" v-model="searchUserStr"
                                        @keydown.enter="searchUser"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="phoneInfo.name" label="姓名" :rules="phoneRules.nameRules">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="phoneInfo.phone" label="手机号" :rules="phoneRules.phoneRules">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog=false">
                        关闭
                    </v-btn>
                    <v-btn text color="primary" @click="newPhone">
                        确定
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Overlay ref="overlay"></Overlay>
    </v-container>
</template>

<script>
    import {
        searchUserByName,
        getUserCompany
    } from "@/request/api/UserApi";
    import {
        getAllDefaultSms,
        putNewAccount
    } from "@/request/api/AccountApi";
    import Overlay from "@/components/Overlay";
    export default {
        name: "newaccount",
        components: {
            Overlay
        },
        data() {
            return {
                menu: false,
                valid: false,
                phoneValid: false,
                rules: {
                    nameRules: [
                        value => !!value || '客户名称不能为空',
                        value => (value || '').length <= 100 || '客户名称过长',
                    ],
                    amountRules: [
                        value => !!value || '金额不能为空',
                        value => /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(
                            value) || '请输入正确的金额',
                        value => Number.parseInt(value) !== 0 || '金额不能为0'
                    ]
                },
                phoneRules: {
                    nameRules: [
                        value => !!value || '姓名不能为空',
                        value => (value || '').length <= 100 || '姓名过长',
                    ],
                    phoneRules: [
                        value => !!value || '手机号不能为空',
                        value => /^[1][3,4,5,7,8,9][0-9]{9}$/.test(value) || '请输入正确的手机号',
                    ]
                },
                accountInfo: {
                    customerName: "",
                    customerAmount: 0.0,
                    sms: [],
                    companyId: 0,
                    date: "",
                },
                defaultSms: [],
                phoneInfo: {
                    name: "",
                    phone: "",
                },
                dialog: false,
                searchUserStr: "",
                companys: [],
                currentCompany: 0,
            }
        },
        methods: {
            searchUser() {
                console.log(this.searchUserStr)
                searchUserByName({
                    name: this.searchUserStr
                }).then((data) => {
                    if (data.code == 0 && data.data.data) {
                        this.phoneInfo = data.data.data;
                    } else {
                        this.$store.commit("showSnackBar", {
                            msg: "没有查询到数据",
                            color: "primary"
                        })
                    }
                })
            },
            openDialog(data) {
                if (data) {
                    this.phoneInfo = data;
                }
                this.searchUserStr = "";
                this.phoneInfo = {
                    name: "",
                    phone: "",
                }
                this.dialog = true;
                setTimeout(() => {
                    this.$refs.phoneForm.resetValidation();
                }, 100)
            },
            newPhone() {
                if (!this.$refs.phoneForm.validate()) {
                    return;
                } else {
                    if (this.accountInfo.sms.findIndex(f => f.phone == this.phoneInfo.phone) > -1 || this.defaultSms
                        .findIndex(f => f.phone == this.phoneInfo.phone) > -1) {
                        this.$store.commit("showSnackBar", {
                            msg: "该用户已添加",
                            color: "primary"
                        })
                        return;
                    } else {
                        this.accountInfo.sms.push(this.phoneInfo);
                        this.phoneInfo = {
                            name: "",
                            phone: "",
                        }
                        this.dialog = false;
                    }
                }
            },
            removePhone(index) {
                this.accountInfo.sms.splice(index, 1);
            },
            submitNewAccount() {
                if (!this.$refs.accountForm.validate()) {
                    return;
                }
                this.$refs.overlay.overlay = true;
                this.accountInfo.companyId = this.currentCompany;
                putNewAccount(this.accountInfo).then(
                    (data) => {
                        if (data.code == 0 && data.data.success) {
                            this.$store.commit("showSnackBar", {
                                msg: "录入成功",
                                color: "primary"
                            });
                            this.clear();
                        } else {
                            this.$store.commit("showSnackBar", {
                                msg: "录入失败，" + data.message,
                                color: "red"
                            });
                        }
                        this.$refs.overlay.overlay = false;
                    }
                )
            },
            clear() {
                this.accountInfo = {
                    customerName: "",
                    customerAmount: 0.0,
                    sms: [],
                    date: this.getNowFormatDate()
                };
                this.$refs.accountForm.resetValidation();
            },
            getNowFormatDate() {
                var date = new Date();
                var seperator1 = "-";
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                var currentdate = year + seperator1 + month + seperator1 + strDate;
                return currentdate;
            }
        },
        mounted() {
            this.$refs.overlay.overlay = true;
            this.accountInfo.date = this.getNowFormatDate();
            getUserCompany().then(
                (data) => {
                    if (data.code == 0) {
                        this.companys = data.data.data;
                        if (this.companys && this.companys.length > 0) {
                            this.currentCompany = this.companys[0].companyId;
                        }
                    }
                }
            )
        },
        watch: {
            "currentCompany": function () {
                //获取必须通知的人和手机号
                getAllDefaultSms(this.currentCompany).then((data) => {
                    if (data.code == 0) {
                        this.defaultSms = [];
                        for (let i = 0; i < data.data.data.length; i++) {
                            let temp = data.data.data[i];
                            this.defaultSms.push({
                                name: temp.smsName,
                                phone: temp.smsPhone
                            });
                        }
                    }
                    this.$refs.overlay.overlay = false;
                })
            }
        }
    }
</script>