import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
    toolbar: {
      zoomIn: 1, // 放大
      zoomOut: 1, // 缩小
      oneToOne: 1, // 100%大小
      reset: 1, // 还原
      prev: 0, // 上一张
      play: 0, // 全屏显示
      next: 0, // 下一张
      rotateLeft: 1, // 逆时针旋转
      rotateRight: 1, // 顺时针旋转
      flipHorizontal: 0, // 水平翻转
      flipVertical: 0 // 垂直翻转
    },
    navbar:false
  },
})

Array.prototype.indexOf = function (val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) return i;
  }
  return -1;
};
Array.prototype.remove = function (val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

import cookieUtil from './utils/CookieUtil';
Vue.prototype.$cookieUtil = cookieUtil;

const hasRole = roleName => {
  var temp = store.state.btnRoles.findIndex(f => {
    return f.name == roleName
  });
  return temp > -1;
}
Vue.prototype.$hasRole = hasRole;

import globalConfig from './utils/GlobalConfig';

Vue.prototype.$globalConfig = globalConfig;
new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount('#app')