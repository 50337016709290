import Vue from 'vue'
import Vuex from 'vuex'
import buttonRoles from '../utils/ButtonRoleUtil'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalSnackbar: {
      turn: false,
      msg: "",
      color: "",
    },
    roleLoaded: false,
    roles: [],
    btnRoles: [],
    userid: "",
    name: "",
    sex: ""
  },
  mutations: {
    refreshRoles(state, roles) {
      var viewRoles = [];
      var btnRoles = [];
      for (let index = 0; index < roles.length; index++) {
        const r = roles[index];
        if (r.btnRole) {
          let temp = buttonRoles.find(f => f.roleId == r.roleId);
          if (temp) {
            btnRoles.push(temp);
          }
        } else {
          viewRoles.push(r);
        }
      }
      state.roles = viewRoles;
      state.btnRoles = btnRoles;
      state.roleLoaded = true;
    },
    showSnackBar(state, options) {
      if (options && options.msg) {
        state.globalSnackbar.msg = options.msg;
        if (options.color) {
          state.globalSnackbar.color = options.color;
        } else {
          state.globalSnackbar.color = "primary";
        }
        state.globalSnackbar.turn = true;
      }
    },
    updateUserInfo(state, data) {
      if (data) {
        if (data.name) {
          state.name = data.name;
        }
        if (data.id) {
          state.userid = data.id;
        }
        if (data.sex) {
          state.sex = data.sex;
        }
      }
    }
  },
  actions: {},
  modules: {}
})