<template>
    <v-container>
        <v-data-table :headers="headers" hide-default-footer disable-filtering disable-pagination disable-sort
            :items="desserts" no-data-text="暂无数据" :loading="loading" loading-text="加载中" class="elevation-1 pt-2">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-btn :color="currentCompanyId == 0 ? 'primary' : 'grey'" dark class="mb-2"
                        @click="currentCompanyId = 0">
                        全部
                    </v-btn>
                    <v-btn v-for="(item,index) in companys" :key="index"
                        :color="currentCompanyId == item.companyId ? 'primary':'grey'" dark class="ml-2 mb-2"
                        @click="currentCompanyId = item.companyId">
                        {{item.companyName}}
                    </v-btn>
                </v-toolbar>
                <v-container class="ma-0 pa-0">
                    <v-row>
                        <v-col cols="6" sm="2" offset-sm="7">
                            <v-select :items="searchType" item-text="name" item-value="key" v-model="currentSearchType"
                                label="搜索类型" dense></v-select>
                        </v-col>
                        <v-col cols="6" sm="2">
                            <v-text-field placeholder="搜索内容" @keyup.enter="search" v-model="currentSearchStr" filled
                                rounded dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn fab x-small elevation="0" class="mr-2" @click="$refs.detail.refreshData(item.accountId)"
                    color="primary">
                    <v-icon small>
                        mdi-eye
                    </v-icon>
                </v-btn>
                <v-btn fab x-small elevation="0" color="red" @click="deleteItem(item)" v-if="$hasRole('removeAccount')">
                    <v-icon small>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-pagination v-model="page" :length="refreshTotalPage()" :total-visible="7"></v-pagination>
        <Detail ref="detail"></Detail>
        <ConfirmDialog ref="confirm"></ConfirmDialog>
    </v-container>
</template>

<script>
    import {
        getAccountInfo,
        removeAccountById
    } from "@/request/api/AccountApi";
    import {
        getAllCompany
    } from "@/request/api/UserApi";
    import Detail from "@/components/AccountDetails";
    import ConfirmDialog from "@/components/ConfirmDialog";
    export default {
        name: "account",
        components: {
            Detail,
            ConfirmDialog
        },
        data() {
            return {
                loading: false,
                companys: [],
                currentCompanyId: 0,
                searchType: [{
                        key: 1,
                        name: "客户名称"
                    },
                    {
                        key: 2,
                        name: "金额"
                    }
                ],
                currentSearchType: 1,
                currentSearchStr: "",
                isSearching: false,
                headers: [{
                    text: '编号',
                    align: 'center',
                    sortable: false,
                    value: 'accountId',
                }, {
                    text: '客户名称',
                    align: 'center',
                    sortable: false,
                    value: 'customerName',
                }, {
                    text: '收款金额',
                    align: 'center',
                    sortable: false,
                    value: 'customerAmount',
                }, {
                    text: '收款时间',
                    align: 'center',
                    sortable: false,
                    value: 'customerDate',
                },{
                    text: '录入人',
                    align: 'center',
                    sortable: false,
                    value: 'userName',
                }, {
                    text: '录入时间',
                    align: 'center',
                    sortable: false,
                    value: 'createTime',
                }, {
                    text: '操作',
                    align: 'center',
                    sortable: false,
                    value: 'actions',
                }],
                desserts: [],
                company: 0,
                total: 0,
                page: 1,
                pageCount: 15,
                currentDeleteItem: null
            }
        },
        methods: {
            refreshData() {
                let params = {
                    page: this.page,
                    com: this.currentCompanyId
                };
                if (this.isSearching) {
                    params = {
                        page: this.page,
                        com: this.currentCompanyId,
                        type: this.currentSearchType,
                        query: this.currentSearchStr
                    };
                }
                getAccountInfo(params)
                    .then(
                        (data) => {
                            if (data.code == 0) {
                                this.total = data.data.total;
                                this.desserts = data.data.data;
                            } else {
                                this.$store.commit("showSnackBar", {
                                    msg: data.message,
                                    color: "orange"
                                });
                            }
                            this.loading = false;
                        }
                    );
            },
            search() {
                if (this.currentSearchStr) {
                    this.isSearching = true;
                    this.page = 1;
                    this.desserts = [];
                    this.refreshData();
                } else {
                    this.$store.commit("showSnackBar", {
                        msg: "请输入搜索条件"
                    })
                }
            },
            refreshTotalPage() {
                return Number.parseInt(this.total / this.pageCount) + ((this.total % this.pageCount) == 0 ? 0 : 1);
            },
            confirm() {
                if (this.currentDeleteItem) {
                    removeAccountById(this.currentDeleteItem.accountId).then(
                        (data) => {
                            if (data.code == 0) {
                                this.$store.commit("showSnackBar", {
                                    msg: "删除成功"
                                })
                                this.refreshData();
                            } else {
                                this.$store.commit("showSnackBar", {
                                    msg: data.message
                                })
                            }
                        }
                    )
                }
            },
            deleteItem(account) {
                this.currentDeleteItem = account;
                this.$refs.confirm.show();
            }
        },
        mounted() {
            this.loading = true;
            getAllCompany().then((data) => {
                if (data.code == 0) {
                    this.companys = data.data.data
                }
            });
            this.refreshData();
        },
        watch: {
            "currentCompanyId": function () {
                this.desserts = [];
                this.page = 1;
                this.isSearching = false;
                this.currentSearchStr = "";
                this.refreshData();
            },
            "page": function () {
                this.loading = true;
                this.desserts = [];
                this.refreshData();
            }
        }
    }
</script>