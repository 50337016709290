import http from '../Http'
const baseuri = "/account";

/**
 * 获取历史账单
 * @param {*} params {page: num, companyId: num} 
 */
export function getAccountInfo(params) {
    return http.get(`${baseuri}/account`, params);
}

/**
 * 获取账单详情
 * @param {*} accountId 
 */
export function getAccountDetail(accountId) {
    return http.get(`${baseuri}/account/${accountId}`);
}

/**
 * 新增账单
 * @param {*} params {customerName: string,customerAmount: decimal,sms: [{name:string,phone:string}]}
 */
export function putNewAccount(params) {
    return http.putBody(`${baseuri}/account`, params);
}

/**
 * 根据ID删除账单
 * @param {*} accountId 
 */
export function removeAccountById(accountId) {
    return http.delete(`${baseuri}/account/${accountId}`);
}

/**
 * 
 * @param {*} companyId number 公司ID
 */
export function getAllDefaultSms(companyId) {
    return http.get(`${baseuri}/dsms/${companyId}`);
}

/**
 * 删除默认通知
 * @param {*} smsId 
 */
export function removeDefaultSms(smsId) {
    return http.delete(`${baseuri}/dsms/${smsId}`)
}

/**
 * 新增默认通知
 * @param {*} params {name:string , phone: string}
 */
export function addNewDefaultSms(params) {
    return http.putBody(`${baseuri}/dsms`, params);
}

/**
 * 识别发票
 * @param {*} params {url: string} 
 */
export function scanInovice(params) {
    return http.post(`${baseuri}/invoice`, params);
}

/**
 * 新增发票
 * @param {*} params 
 */
export function addNewInvoice(params) {
    return http.putBody(`${baseuri}/invoice`, params);
}

/**
 * 分页获取发票信息
 * @param {*} params {page:num,com: num}
 */
export function getInvoiceInfos(params) {
    return http.get(`${baseuri}/invoice`, params);
}

/**
 * 根据ID删除发票
 * @param {*} invoiceId 
 */
export function removeInvoiceById(invoiceId) {
    return http.delete(`${baseuri}/invoice/${invoiceId}`);
}

/**
 * 获取发票详情
 * @param {*} invoiceId 
 */
export function getInvoiceDetail(invoiceId) {
    return http.get(`${baseuri}/invoice/${invoiceId}`);
}

export function uploadWordImage(params){
    return http.post(`${baseuri}/ocr`,params);
}