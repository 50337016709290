<template>
    <v-container>
        <v-app-bar flat color="transparent">
            <v-btn v-for="(item,index) in companys" :key="index"
                :color="currentCompanyId == item.companyId ? 'primary':'grey'" dark class="ml-2 mb-2"
                @click="currentCompanyId = item.companyId">
                {{item.companyName}}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="$hasRole('addDefaultSms')" @click="openDialog">添加</v-btn>
        </v-app-bar>
        <v-card class="ma-4 pa-4" flat>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="10" offset-sm="1" v-for="(item,index) in desserts" :key="index">
                            <v-card>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field readonly :value="item.smsName" label="姓名"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field readonly :value="item.smsPhone" label="手机"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="$hasRole('removeDefaultSms')" color="red" @click="removeSms(item)">删除
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span>
                        新增通知
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form lazy-validation ref="phoneForm">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field prepend-inner-icon="mdi-magnify" dense flat hide-details rounded
                                        solo-inverted placeholder="输入姓名搜索" v-model="searchUserStr"
                                        @keydown.enter="searchUser"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="phoneInfo.name" label="姓名" :rules="phoneRules.nameRules">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="phoneInfo.phone" label="手机号" :rules="phoneRules.phoneRules">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialog=false">
                        关闭
                    </v-btn>
                    <v-btn text color="primary" @click="newPhone">
                        确定
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {
        searchUserByName,
        getAllCompany
    } from "@/request/api/UserApi";
    import {
        getAllDefaultSms,
        addNewDefaultSms,
        removeDefaultSms
    } from "@/request/api/AccountApi";
    export default {
        name: "defaultsms",
        data() {
            return {
                dialog: false,
                phoneRules: {
                    nameRules: [
                        value => !!value || '姓名不能为空',
                        value => (value || '').length <= 100 || '姓名过长',
                    ],
                    phoneRules: [
                        value => !!value || '手机号不能为空',
                        value => /^[1][3,4,5,7,8,9][0-9]{9}$/.test(value) || '请输入正确的手机号',
                    ]
                },
                phoneInfo: {
                    name: "",
                    phone: "",
                    companyId: 0,
                },
                companys: [],
                currentCompanyId: 0,
                searchUserStr: "",
                desserts: []
            }
        },
        methods: {
            searchUser() {
                searchUserByName({
                    name: this.searchUserStr
                }).then((data) => {
                    if (data.code == 0 && data.data.data) {
                        this.phoneInfo = data.data.data;
                    } else {
                        this.$store.commit("showSnackBar", {
                            msg: "没有查询到数据",
                            color: "primary"
                        })
                    }
                })
            },
            openDialog() {
                this.dialog = true;
                this.phoneInfo = {
                    name: "",
                    phone: "",
                };
                setTimeout(() => {
                    this.$refs.phoneForm.resetValidation();
                }, 10);
            },
            newPhone() {
                if (!this.$refs.phoneForm.validate()) {
                    return;
                } else {
                    this.phoneInfo.companyId = this.currentCompanyId;
                    addNewDefaultSms(this.phoneInfo).then(
                        (data) => {
                            if (data.code == 0) {
                                this.$store.commit("showSnackBar", {
                                    msg: "添加成功",
                                });
                                this.refreshData();
                                this.dialog = false;
                            }
                        }
                    )
                }
            },
            removeSms(data) {
                if (data) {
                    removeDefaultSms(data.id).then(
                        (data) => {
                            if (data.code == 0 && data.data.success) {
                                this.$store.commit("showSnackBar", {
                                    msg: "删除成功",
                                });
                                this.refreshData();
                            }
                        }
                    )
                }
            },
            refreshData() {
                getAllDefaultSms(this.currentCompanyId).then(
                    (data) => {
                        if (data.code == 0) {
                            this.desserts = data.data.data;
                        }
                    }
                )
            }
        },
        mounted() {
            getAllCompany().then(
                (data) => {
                    if (data.code == 0) {
                        this.companys = data.data.data
                        if (this.companys && this.companys.length > 0) {
                            this.currentCompanyId = this.companys[0].companyId;
                        }
                    }
                }
            )
        },
        watch: {
            "currentCompanyId": function () {
                this.desserts = [];
                this.refreshData();
            }
        }
    }
</script>