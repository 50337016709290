var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1 pt-2",attrs:{"headers":_vm.headers,"hide-default-footer":"","disable-filtering":"","disable-pagination":"","disable-sort":"","items":_vm.desserts,"no-data-text":"暂无数据","loading":_vm.loading,"loading-text":"加载中"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":_vm.currentCompanyId == 0 ? 'primary' : 'grey',"dark":""},on:{"click":function($event){_vm.currentCompanyId = 0}}},[_vm._v(" 全部 ")]),_vm._l((_vm.companys),function(item,index){return _c('v-btn',{key:index,staticClass:"ml-2 mb-2",attrs:{"color":_vm.currentCompanyId == item.companyId ? 'primary':'grey',"dark":""},on:{"click":function($event){_vm.currentCompanyId = item.companyId}}},[_vm._v(" "+_vm._s(item.companyName)+" ")])})],2),_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"2","offset-sm":"7"}},[_c('v-select',{attrs:{"items":_vm.searchType,"item-text":"name","item-value":"key","label":"搜索类型","dense":""},model:{value:(_vm.currentSearchType),callback:function ($$v) {_vm.currentSearchType=$$v},expression:"currentSearchType"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"2"}},[_c('v-text-field',{attrs:{"placeholder":"搜索内容","filled":"","rounded":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},model:{value:(_vm.currentSearchStr),callback:function ($$v) {_vm.currentSearchStr=$$v},expression:"currentSearchStr"}})],1)],1)],1)]},proxy:true},{key:"item.invoiceBuyerName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(!item.invoiceBuyerName ? "" : item.invoiceBuyerName.length > 15 ? item.invoiceBuyerName.slice(0,15) + "..." : item.invoiceBuyerName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.invoiceBuyerName))])])]}},{key:"item.invoiceItems",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(!item.invoiceItems ? "" : item.invoiceItems.length > 10 ? item.invoiceItems.slice(0,10) + "..." : item.invoiceItems)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.invoiceItems))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.$refs.detail.refreshData(item.invoiceId)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1),(_vm.$hasRole('removeInvoice'))?_c('v-btn',{attrs:{"fab":"","x-small":"","elevation":"0","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])}),_c('v-pagination',{attrs:{"length":_vm.refreshTotalPage(),"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('Detail',{ref:"detail"}),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }