import http from "../Http"
const baseuri = '/user'

export function getRole() {
    return http.get(`${baseuri}/role/app/2`)
}

export function getInfo() {
    return http.get(`${baseuri}/base`);
}

/**
 * 获取所有公司
 */
export function getAllCompany() {
    return http.get(`${baseuri}/base/company`);
}

/**
 * 查询用户
 * @param {*} param 
 */
export function searchUserByName(param) {
    return http.get(`${baseuri}/base/user`, param);
}

/**
 * 获取当前登录用户的公司信息
 */
export function getUserCompany() {
    return http.get(`${baseuri}/company`);
}